/** @jsx jsx */
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { jsx, Container, Flex, Box, Heading, Image, Text } from 'theme-ui';
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "../components/shared/Link";
import Layout from '../components/Layout';
import SEO from "../components/seo";
import ContentfulRichText from "../components/shared/ContentfulRichText";
import bgImage from '../images/bg-data-hexagons.png';
import mobileBgImage from '../images/bg-data-hexagons-mobile.png';
import CaseStudyOrgDetails from "../components/CaseStudyOrgDetails";
import GenerateSection from "./generateSections";
import CardWrapper from "../components/shared/CardWrapper";

const shortcodes = { Link };

export default function PageTemplate({ data }) {
  const pageData = data.mdx ? data.mdx : data.contentfulCaseStudy;
  const companyDetails = data.contentfulCaseStudy?.companyDetails;
  const title = pageData?.title || pageData?.frontmatter?.title;
  const subheading = pageData?.subheading?.trim() || 'Great Expectations Case Study:';
  const description = pageData?.description || pageData?.frontmatter?.description;
  const featuredImage = pageData?.featuredImage?.gatsbyImageData || pageData?.frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData;
  const featuredImageAltText = pageData?.featuredImageAltText || pageData?.frontmatter?.imageAltText;
  const featuredImageCaption = pageData?.featuredImageCaption || pageData?.frontmatter?.imageCaption;
  const mainContent = pageData?.postBody || pageData?.body;
  const sections = pageData?.sections;
  const seo = pageData?.seoMetadata;
  const isHidden = !!pageData.isHidden;
  const moreCaseStudies = data?.allContentfulCaseStudy?.edges;

  return (
    <MDXProvider components={shortcodes}>
      <Layout>
        <SEO
            title={seo?.title}
            description={seo?.description}
            ogImage={`https:${seo?.ogImage?.file?.url}`}
            keywords={seo?.keywords}
            fullImagePath={true}
            robots={isHidden ? "noindex, nofollow" : null}
        />
        <Box sx={{ position: 'relative', paddingInline: [10, 'unset'] }} className="blog case-study" >
          <Box sx={{ display: ['none', 'block'] }}>
            <Image src={bgImage} alt="backgroundImage" className="background-image" />
          </Box>
          <Box sx={{ display: ['block', 'none'] }}>
            <Image src={mobileBgImage} alt="backgroundImage" className="background-image" />
          </Box>
          <Container sx={{ paddingInline: ['0', '20px'] }}>
            <Flex sx={{ marginInline: 'auto', padding: ['20px 0 0px 0', '40px 0 0px 0'], textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
              <Heading as="h3" sx={{ fontWeight: 'bold', color: "#2C489E", textTransform: "uppercase", fontSize:[20, 24, 28] }}>{subheading}</Heading>
            </Flex>
            {title &&
              <Flex sx={{ marginInline: 'auto', padding: ['20px 0 40px 0', '0px 0 40px 0'], textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <Heading as="h1" sx={{ fontWeight: 'bold' }}>{title}</Heading>
              </Flex>
            }
            {/* {description &&
              <Flex sx={{ marginInline: 'auto', padding: ['20px 0 60px 0', '0px 0 60px 0'], textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <Heading as="h3" sx={{ fontWeight: 'normal' }}>{description}</Heading>
              </Flex>
            } */}
            <Flex
              sx={{
                '@media screen and (max-width: 900px)': {
                  flexDirection: "column",
                  order: 2
                },
                marginBottom: ['50px'],
                justifyContent: 'space-between'
              }}
            >
              <Box sx={{
                maxWidth: "70%",
                '@media screen and (max-width: 900px)': {
                  maxWidth: "unset"
                },
                paddingTop: [10, 0]
              }} className="blog">
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 4,
                    lineHeight: 'none'
                  }}
                >
                  <GatsbyImage
                    image={featuredImage}
                    alt={featuredImageAltText}
                  />
                  <caption sx={{ fontStyle: "italic", mt: 2 }}>
                    {featuredImageCaption}
                  </caption>
                </Flex>
                {description &&
                  <Flex className="case-study-description" sx={{ marginInline: 'auto', padding: ['20px 0 60px 0', '0px 0 5px 0'], textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                    <Heading as="h3" sx={{ fontWeight: 'normal' }}>{description}</Heading>
                  </Flex>
                }
                {data.mdx ?
                  (
                    <MDXRenderer>
                      {mainContent}
                    </MDXRenderer>
                  ) : (
                    <ContentfulRichText richText={mainContent} />
                  )
                }
              </Box>
              <Box sx={{
                maxWidth: '300px',
                '@media screen and (max-width: 900px)': {
                  order: 3,
                  maxWidth: "unset"
                },
              }}>
                {companyDetails && <CaseStudyOrgDetails companyDetails={companyDetails} />}
              </Box>
            </Flex>


          </Container>
        </Box>
        <Box sx={{ minHeight: "300px", backgroundColor: "#F5F6FA"}} className="more-case-study">
          {moreCaseStudies.length > 0 &&
            <Container sx={{ paddingInline: ['0', '20px'] }}>
            <Flex sx={{ marginInline: 'auto', padding: ['20px 0 0px 0', '60px 0 0px 0'], textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
              <Heading as="h2" sx={{ fontWeight: 'bold' }}>More Case Studies</Heading>
            </Flex>
            <Box sx={{
              display: 'flex',
              rowGap: "30px",
              listStyle: 'none',
              flexWrap: 'wrap',
              justifyContent: ['center', null, 'space-between'],
              alignItems: 'center',
              padding: 0,
              my: [2, 4],
              '&::after': {
                content: '""',
                flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%']
              }
            }}>
              {moreCaseStudies.map(({ node: post }) => (
                <Box
                  key={post.id}
                  sx={{
                    flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%'],
                    display: 'flex',
                    margin: '6px 0',
                  }}
                >
                  <CardWrapper
                    item={{
                      heading: post?.frontmatter?.title || post?.title,
                      image: post?.frontmatter?.featuredImage?.childImageSharp || post?.featuredImage,
                      imageAltText: post?.frontmatter?.title || post?.featuredImageAltText,
                      linkUrl: `/case-studies/${post.slug}`
                    }}
                    type="Case Study"
                  />

                </Box>
              ))}
            </Box>
          </Container>
          }
          {sections && sections.map((section) => (
            <GenerateSection key={section.id} section={section} />
          ))}
        </Box>
      </Layout>
    </MDXProvider>
  );
}

export const pageQuery = graphql`
  query CaseStudyQuery($id: String, $tags: [String]) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        author
        date(formatString: "MMMM DD, YYYY")
        imageCaption
        imageAltText
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    contentfulCaseStudy(id: { eq: $id })  {
      id
      __typename
      subheading
      title
      seoMetadata {
        ogImage {
          file {
            url
          }
        }
        title
        description
        keywords
      }
      description
      author
      date(formatString: "MMMM DD, YYYY")
      featuredImageCaption
      featuredImageAltText
      featuredImage {
        gatsbyImageData
      }
      companyDetails {
        stat1
        stat1Subtitle
        stat2
        stat2Subtitle
        quoteAuthor
        description
        descriptionSubtitle
        components
        logo { 
          gatsbyImageData(width: 300)
        }
        qoute {
          qoute
        }
      }
      postBody {
        raw
      }
      sections {
        __typename
        ... on ContentfulHeroSection {
          ...ContentfulHeroSectionFragment
          id
        }
      }
    }
    contentfulCallToActionBanner(
      bannerTitle: {eq: "Scroll Reveal Banner"}
    ) {
      bannerHeading
      bannerText
      bannerAction {
        ...ActionFragment
      }
      backgroundImage {
        gatsbyImageData
      }
      icons {
        internalName
        icon
        iconSize
        style
        url
        id
      }
    }
      allContentfulCaseStudy(           
          filter: {
              id: { ne: $id },
              metadata: {
                  tags: { elemMatch: { contentful_id: { in: $tags } } }
              }
          }
          sort: { fields: date, order: DESC }
          limit: 3
      ) {
          edges {
              node {
                  id
                  slug
                  description
                  title
                  author
                  date(formatString: "MMMM DD, YYYY")
                  featuredImage {
                      gatsbyImageData(placeholder: NONE, quality: 85, layout: FULL_WIDTH)
                  }
                  featuredImageAltText
              }
          }
          totalCount
      }
  }
`;
