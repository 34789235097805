/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { jsx, Flex, Heading, Text, Paragraph, Box } from 'theme-ui';
import Action from "./shared/Action";
import Icon from "./shared/Icon";

const CaseStudyOrgDetails = ({ companyDetails }) => {
    const { contentfulCallToActionBanner } = useStaticQuery(graphql`
    query {
      contentfulCallToActionBanner(
        bannerTitle: {eq: "Scroll Reveal Banner"}
      ) {
        bannerHeading
        bannerText
        bannerAction {
          ...ActionFragment
        }
        backgroundImage {
          gatsbyImageData
        }
        icons {
          internalName
          icon
          iconSize
          style
          url
          id
        }
      }
    }
  `);

    const { bannerAction, backgroundImage, icons } = contentfulCallToActionBanner;
    const handleSocialClick = (event, pre)=> {
        event.preventDefault();
        const href = pre + window.location.href;
        window.open(href, '_blank');
      };
    return (
        <Box sx={{ padding: ['10px', ' 40px 10px'], mb: 2, textAlign: 'left', justifyContent: 'center', alignItems: 'center', backgroundColor: "#F5F6FA", minHeight: "700px" }}>
            <Flex sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <GatsbyImage
                    sx={{ maxWidth: "300px" }}
                    image={companyDetails.logo.gatsbyImageData}
                />
            </Flex>
            <Box sx={{ mt: 4, mx: "10px", borderTop: "1px solid #D0D1D4" }}></Box>
            <Flex sx={{ flexDirection: "column" }}>
                <Box sx={{ mt: 4, mx: "10px" }}>
                    <Heading as="h6" sx={{ fontWeight: "bold", color: "#FF6310" }}>{companyDetails.stat1}</Heading>
                </Box>
                <Box sx={{ mx: "10px" }}>
                    <Heading as="h6" sx={{ fontWeight: "normal" }}>{companyDetails.stat1Subtitle}</Heading>
                </Box>
                <Box sx={{ mt: 4, mx: "10px" }}>
                    <Heading as="h6" sx={{ fontWeight: "bold", color: "#FF6310" }}>{companyDetails.stat2}</Heading>
                </Box>
                <Box sx={{ mx: "10px" }}>
                    <Heading as="h6" sx={{ fontWeight: "normal" }}>{companyDetails.stat2Subtitle}</Heading>
                </Box>
                <Box sx={{ mt: 4, mx: "10px" }}>
                    <Heading as="h6" sx={{ fontWeight: "bold", color: "#FF6310" }}>{companyDetails.description}</Heading>
                </Box>
                <Box sx={{ mx: "10px" }}>
                    <Heading as="h6" sx={{ fontWeight: "normal" }}>{companyDetails.descriptionSubtitle}</Heading>
                </Box>
            </Flex>
            <Box sx={{ mt: 3, mx: "10px", borderTop: "1px solid #D0D1D4" }}></Box>
            <Flex sx={{ flexDirection: "column" }}>
                <Box sx={{ mt: 3, mx: "10px" }}>
                    <Heading as="h6" sx={{ fontWeight: "500" }}>Components</Heading>
                </Box>
                <Box sx={{ mt: 3, mx: "10px" }} className="comapny-details-components">
                    <ul sx={{ fontSize: "12px" }}>
                        {companyDetails.components.map((text) => <li sx={{ fontSize: "12px" }}>{text}</li>)}
                    </ul>
                </Box>
            </Flex>
            <Box sx={{ mt: 2, mx: "10px", borderTop: "1px solid #D0D1D4" }}></Box>
            <Box sx={{ mt: 3, pt: 4, pb: 4, px: "10px", backgroundColor: "#E8EBF5", mx: "10px", borderLeft: "8px solid #223F99" }}>
                <Box sx={{ color: '#223F99' }}>
                  <Icon icon='ri-double-quotes-l' iconSize={50}/>
                </Box>
                <Box>
                    <Text >
                        "{companyDetails.qoute.qoute}"
                    </Text>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Text sx={{ fontWeight: "bold" }}>{companyDetails.quoteAuthor ? "-" + companyDetails.quoteAuthor : ""}</Text>
                </Box>
            </Box>
            <Box sx={{ mt: 3, mx: "10px", borderTop: "1px solid #D0D1D4" }}></Box>
            <Box sx={{ mt: 3, padding: '10px 10px', marginLeft: ['0', 0, null, '0'] }} className="social">
                <Box>
                    <Heading as="h7" sx={{ fontWeight: '600' }}>SHARE THIS ARTICLE</Heading>
                </Box>
                <Flex mt={2}>
                    {icons && icons.map((icon) => (
                        <Box sx={{ marginRight: '5px' }}>
                            {icon.internalName === "Mail" ?
                                <a rel="noreferrer" target="_blank"
                                    onClick={(event) => handleSocialClick(event, icon.url + "subject=" + title + "&body=")}
                                    key={icon.id}
                                >
                                    <Action icon={icon?.icon} iconSize={icon?.iconSize} style={icon?.style} actionEvent={icon?.actionEvent} label={icon?.label} footer={true} />
                                </a>
                                :
                                <a rel="noreferrer" target="_blank"
                                    onClick={(event) => handleSocialClick(event, icon.url)}
                                    key={icon.id}
                                >
                                    <Action icon={icon?.icon} iconSize={icon?.iconSize} style={icon?.style} actionEvent={icon?.actionEvent} label={icon?.label} footer={true} />
                                </a>
                            }
                        </Box>
                    ))}
                </Flex>
            </Box>
        </Box>
    );
};
export default CaseStudyOrgDetails;
